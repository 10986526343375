<template>
  <VWidget v-bind="$attrs">
    <template v-slot:content>
      <v-row justify="space-between">
        <v-col
          cols="12"
          :md="buttons.add ? 6 : 12"
          class="text-h6 font-weight-bold black--text"
        >
          {{ title }}
        </v-col>
        <v-col cols="12" md="6" class="text-right" v-if="buttons.add">
          <v-btn
            :color="
              user.broker && whiteBranding ? 'primary' : 'submit-button-index'
            "
            v-can="`create-${section}`"
            rounded
            x-large
            @click="$emit('add', $event)"
          >
            {{ $t(addText) }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- TOOLBAR BUTTONS -->
      <v-row>
        <v-col cols="12">
          <BaseButtonsList
            v-on="$listeners"
            v-bind="$attrs"
            :selected="selected"
            :section="section"
            :buttons="buttons"
          >
            <!-- Agregamos todos los slots de los componentes al Slot Principal -->
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
              <slot :name="slot" v-bind="scope" />
            </template>
          </BaseButtonsList>
        </v-col>
      </v-row>

      <v-row justify="space-between" v-if="subTitle">
        <v-col cols="12" md="6" class="text-h6 font-weight-bold black--text">
          {{ subTitle }}
        </v-col>
      </v-row>

      <!-- FILTERS -->
      <slot name="filters"></slot>

      <!-- DATA TABLE -->
      <v-row>
        <v-col cols="12">
          <BaseTable
            :value="selected"
            v-bind="$attrs"
            v-on="$listeners"
            :headers="headers"
            :items="items"
            :show-select="showSelect"
            :section="section"
            :buttons="buttons"
            :height="height"
            :search="search"
            @input="$emit('update:selected', $event)"
          >
            <!-- Agregamos todos los slots de los componentes al Slot Principal -->
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
              <slot :name="slot" v-bind="scope" />
            </template>
          </BaseTable>
        </v-col>
      </v-row>
    </template>
  </VWidget>
</template>
<script>
import VWidget from './VWidget'
import BaseButtonsList from './BaseButtonsList'
import BaseTable from './BaseTable'
import { mapState } from 'vuex'

export default {
  name: 'BaseList',
  components: { VWidget, BaseTable, BaseButtonsList },
  model: {
    prop: 'selected',
    event: 'input'
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    addText: {
      type: String,
      default: 'add'
    },
    section: {
      type: String,
      default: ''
    },
    color: String,
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    buttons: {
      type: Object,
      default: () => ({
        add: true,
        refresh: true,
        search: true,
        edit: true,
        delete: true,
        print: false
      })
    },
    height: {
      type: String,
      default: '25vh'
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('setting', ['whiteBranding'])
  }
}
</script>

<style>
.submit-button-index {
  /* width: 208px; */
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
  color: white !important;
}
</style>
